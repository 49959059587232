@font-face {
  font-family: "Cera Pro";
  src: url("./fonts/cerapro-light.woff") format("woff");
  font-weight: 300;
  font-variant: normal;
}
@font-face {
  font-family: "Cera Pro";
  src: url("./fonts/cerapro-regular.woff") format("woff");
  font-weight: 400;
  font-variant: normal;
}
@font-face {
  font-family: "Cera Pro";
  src: url("./fonts/cerapro-medium.woff") format("woff");
  font-weight: 500;
  font-variant: normal;
}
@font-face {
  font-family: "Cera Pro";
  src: url("./fonts/cerapro-bold.woff") format("woff");
  font-weight: 700;
  font-variant: normal;
}
