.static-content {
  font-size: 1em;
  line-height: 1.5em;

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  h2 {
    font-size: 1.5em;
    margin: 0.83em 0;
  }
  h3 {
    font-size: 1.17em;
    margin: 1em 0;
  }
  h4 {
    font-size: 1em;
    margin: 1.33em 0;
  }
  h5 {
    font-size: 0.83em;
    margin: 1.67em 0;
  }
  h6 {
    font-size: 0.67em;
    margin: 2.33em 0;
  }

  a {
    text-decoration: underline;
  }

  p {
    margin-bottom: 1em;
  }

  ol, ul {
    margin: 1em 0;
    padding-left: 2.5em;

    li {
      &:not(:last-child) {
        margin-bottom: 1em;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  blockquote {
    border-left: 10px solid white;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
    color: white;

    &:before {
      color: white;
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }

    p {
      display: inline;
    }
  }

  .page-terms-of-service & ol {
    counter-reset: item;
    padding-left: 0;

    > li {
      display: block;

      &:before {
        content: counters(item, ".") ". ";
        counter-increment: item;
      }
    }
  }
}
