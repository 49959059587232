html,
body {
  position: relative;
  z-index: 0;
  color: #d1d6db;
  font-smooth: antialiased;
  background: #050035;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: "Cera Pro", Verdana, Geneva, Tahoma, sans-serif;
}

.intl-tel-input.allow-dropdown .selected-flag {
  width: 77px !important;
  height: 97% !important;
  bottom: -1px;
  left: 1px;
  background: #47505d;
  border-radius: 4px;
}

.intl-tel-input.allow-dropdown input[type="tel"] {
  padding-left: 96px !important;
}

.intl-tel-input.allow-dropdown .country-list {
  z-index: 30;
  color: white;
  border: none;
  border-radius: 6px;
}

.intl-tel-input .country-list {
  background-color: rgb(3 22 41) !important;
}

.intl-tel-input.allow-dropdown .country-list .country:hover {
  background-color: rgb(51 59 72);
}

.intl-tel-input.allow-dropdown .country-list .divider {
  border-color: rgb(51 59 72);
}

.fade-out-mask {
  -webkit-mask-image: linear-gradient(to bottom, white, transparent);
  mask-image: linear-gradient(to bottom, white, transparent);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 32px rgb(51 59 72) inset !important;
  -webkit-box-shadow: 0 0 0 32px rgb(51 59 72) #47505d inset !important;
  -webkit-text-fill-color: white;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
