.seatlabnftCalendar .calendar-day-date__primary + .calendar-day-date__primary:not(.first-of-week):before,
.seatlabnftCalendar .calendar-day-date__primary + .calendar-day-date__secondary:not(.first-of-week):before,
.seatlabnftCalendar .calendar-day-date__secondary + .calendar-day-date__secondary:not(.first-of-week):before,
.seatlabnftCalendar .calendar-day-date__secondary + .calendar-day-date__primary:not(.first-of-week):before {
  content: "";
  position: absolute;
  display: block;
  width: 0.9rem;
  left: -0.85rem;
  top: 0;
  bottom: 0;
  background-color: #333b48;
}
